/* Extra small devices (phones, less than 768px) */
@media only screen and (max-width: 767px) {
  body {
    padding: 0;
  }

  .wrapper {
    margin: 0;
    padding: $spacing-unit / 2;
    box-shadow: none;

    section {
      .section-content {
        .items {
          flex-direction: column;

          .item {
            .row {
              flex-direction: column;
            }
          }
        }
      }
    }

    .basic {
      flex-direction: column;

      .avatar {
        display: block;
        margin: $spacing-unit auto;
        border-radius: 100%;
      }

      .header {
        text-align: center;

        .contact {
          flex-direction: column;
          width: 100%;

          .contact-item:not(:last-child)::after {
            content: none;
          }

        }
      }
    }

    .certificate,
    .award,
    .reference {
      .section-content {
        .items {
          flex-direction: column;
        }
      }
    }

    .skill {
      .section-content {
        .items {
          flex-direction: column;
          margin: auto;
          width: fit-content;
        }

        .groups {
          .group {
            flex-direction: column;

            &:not(:last-child) {
              border-bottom: none;
            }

            .group-item {
              justify-content: start;
            }
          }
        }
      }
    }

    .language,
    .interest {
      .section-content {
        .items {
          flex-direction: column;

          .item:not(:last-child)::after {
            content: none;
          }
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Medium devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

/* Ex-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {}
