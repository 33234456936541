@charset "utf-8";

// Define defaults for each variable.
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;

$small-font-size:  $base-font-size * 0.875 !default; // 14px
$middle-font-size: $base-font-size * 1.125 !default; // 18px
$large-font-size:  $base-font-size * 1.25  !default; // 20px
$huge-font-size:   $base-font-size * 2     !default; // 32px

$base-line-height: 1.5 !default;

$spacing-unit:     20px !default;

// Width of the content area
$content-width:    1000px !default;

// Default color settings
$text-color:        #333333;
$text-second-color: #3F4650;
$text-third-color:  #97AAC3;
$background-color:  #f5f5f5;
$wrapper-color:     #ffffff;
$heart-color:       #fb866a;
$theme-color:       #1F618D;

// Default delimiter
$contact-delimiter: "·";

@import
  "sanitize",
  "base",
  "responsive",
  "print",
  "fonts"
;

section {
  .section-title {
    text-transform: uppercase;
    justify-content: start;
    border-bottom-style: dashed;

    

    
  }

  
  .section-content {
    .markdown, .items {
      a::after {
        display: inline-block;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font: var(--fa-font-solid);
        content: "\f08e";
        color: $text-third-color;
        font-size: 0.625em;
        margin: 0 0.375em;
        text-decoration: none;
      }
    }
  }
  
}
