@media print {
  @page {
    size: A4;
  }

  body {
    font-size: 12pt !important;
    background: $wrapper-color !important;
  }

  a::after {
    content: none !important;
  }

  h1, h2, h3, h4, h5, h6, img {
    break-after: avoid !important;
    break-inside: avoid !important;
  }

  ul, ol {
    break-before: avoid !important;
  }

  a, blockquote, pre {
    break-inside: avoid !important;
  }

  .wrapper {
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .footer {
    display: none !important;
  }
}
